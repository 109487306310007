import React, { useState } from 'react';

import Layout from '../../components/organisms/layout/layout';
import WeContent from '../../components/organisms/we-content/we-content';

const SomosFamilia = () => {
  const menuActive = 1;
  const [modalActive, setmodalActive] = useState(null)

  return (
    <Layout
      menuActive={menuActive}
      setmodalActive={setmodalActive}
      modalActive={modalActive}
      title={
        process.env.LOCALE === 'es-CO'
          ? 'Conoce más sobre nuestra empresa - Familia Hogar'
          : process.env.LOCALE === 'es-EC'
          ? '¿Quiénes somos? Conócenos - Familia Hogar'
          : process.env.LOCALE === 'es-DO'
          ? 'Conoce más sobre nosotros - Familia Hogar'
          : process.env.LOCALE === 'es-PR'
          ? 'Conoce sobre nuestra marca - Familia hogar'
          : ''
      }
      slug={'/nosotros/somos-familia/'}
    >
      <WeContent page={0} />
    </Layout>
  );
};

export default SomosFamilia;
